<template>
  <base-section
    id="hem-teater"
  >
    <base-section-heading title="Hemteater">
    </base-section-heading>

    <v-container>
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          md="5"
        >
      <p>Nya tider kräver nya lösningar. Vi kör ett nytt koncept, det handlar om en uppsökande teaterföreställning, 
      vi kommer hem till er och spelar för er och era närmaste vänner/familj i vardagsrummet, garaget, verandan 
      eller där det passar. Naturligtvis ser vi till att det blir så tryggt och säkert som möjligt för alla ur 
      smittsynpunkt. Låter det här intressant? Ta kontakt med oss genom <a href="mailto:info@johker.se" title="Hemteater">info@johker.se</a> och läs mer om pjäsen genom att klicka nedan.
      </p>
      <p>Läs även vad <a href="https://corren.se/kultur-noje/artikel/gruppen-tar-nytt-corona-grepp-med-hemteater/5redyexj" target="_blank" rel="noopener">Corren skriver om det i sin artikel</a>.
      </p>
          <div class="text-center">
            <base-btn
              :tile="false"
              color="primary"
              href="https://www.johker.se/produktioner"
              rounded
            >
              Mer om pjäsen
            </base-btn>
          </div>


        </v-col>

        <v-col
          cols="12"
          md="2"
        >
            <base-img
              max-width="100%"
              width="230"
              :src="require(`@/assets/banner.png`)"
            />

        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionProChart',

    data: () => ({
      features: [
        ['Components', 23, '40+'],
        ['Example Pages', 3, 10],
        ['Bugfixes and Issues', true, true],
        ['Vue CLI Support', true, true],
        ['News, Project, and Pricing Pages', false, true],
        ['6 Months Free Updates', false, true],
        ['Price', 'Free', '$60'],
      ],
    }),
  }
</script>
